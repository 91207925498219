.ant-picker-input > input::placeholder {
  color: #6b7280 !important;
}

.ant-picker-input-placeholder > input {
  color: #6b7280 !important;
}

.ant-picker-suffix {
  color: rgba(0, 0, 0, 0.75) !important;
}
