/* ./src/index.css */
@import "~antd/dist/antd.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.react-markdown {
  overflow: hidden;
}
.react-markdown p {
  display: contents;
}

.min-h-50 {
  min-height: 50vh;
}
